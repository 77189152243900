import * as React from "react";
import {useEffect, useState} from "react";
import {useEventListener} from "./useEventListener";


type returnType={width:number,height:number}

export const useWindowSize=():returnType=>{
    const { innerWidth: width, innerHeight: height } = window;
    const [result,setResult]=useState<returnType>({width:width,height:height});

    const resizeHandle=()=>{
        const { innerWidth: width, innerHeight: height } = window;
        setResult({width:width,height:height});
    }

    // useEffect(()=>{
    //     const { innerWidth: width, innerHeight: height } = window;
    //     setResult({width:width,height:height});
    // },[]);

    useEventListener("resize", resizeHandle);

    return result;
}