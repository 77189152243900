import * as React from "react";
import {useEffect, useState} from "react";
import {LevelType} from "../lib/Types";


export const useLevelsData=()=>{

    const [data,setData]=useState<LevelType[]>([]);

        useEffect(()=>{
            let cleanupFunction = false;
            fetch(process.env.PUBLIC_URL+"/levels.json")
                .then(response => response.json())
                .then((jsonData) => {
                    if(!cleanupFunction) setData(jsonData);
                })
                .catch((error) => {
                    console.error(error);
                });
            return () => {cleanupFunction = true};
        },[]);


        return data;
    };