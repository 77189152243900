import * as React from 'react';
import {createUseStyles} from "react-jss";
import ReactSVG from "react-svg";


type Props={
   number:number,
    text:string,
    color:string,
}
const useStyles=createUseStyles((props?:any)=>({
    scientists:{
        textAlign:"center",
        width:"400px",
        position:"absolute",
        zIndex:"3",
        left:"50%",
        top:"50%",
        marginLeft:"-200px",
        marginTop:"-230px",
        "@media  only screen and (min-width:319px) and (max-width: 715px)":{

            marginLeft:"-200px",
            marginTop:"-190px",
        },
        "& svg": {
            height: 235
        },

        "& div.svg":{
        "& path#colored1,path#coloredname,path[id^='colored1-'],path[id^='coloredname-']":{
            //svg > g > g > g > path:nth-of-type(1), svg > g > g > g >  path:nth-of-type(2){
            // fill: #00a893;
            fill: (props:any)=>props.color
        }

        },
        "&  .text":{
            fontSize:12,
            position:"absolute",
            display:"flex",
            alignItems:"center",
            left:"57px",
            top:"94px",
            height:"71px",
            width:"95px",
            color:"#fff",
            "& span":{
                textAlign:"center",
                width:"100%"
            }

        },
        "&.n0":{
            "& div.text":{
                left:"67px",
                top:"94px",
                height:"71px",
                width:"95px",
            },
            "span":{  }
        },
        "&.n1":{
            "& div.text":{
                left:"58px",
                top:"104px",
                height:"71px",
                width:"105px",
            },
            "span":{  }
        },
        "&.n2":{
            "& div.text":{
                left:"62px",
                top:"89px",
                height:"67px",
                width:"89px",
            },
            "span":{  }
        },
        "&.n3":{
            "& div.text":{
                left:"61px",
                top:"97px",
                height:"68px",
                width:"91px",
            },
            "span":{  }
        },
        "&.n4":{
            "& div.text":{
                left:"51px",
                top:"91px",
                height:"71px",
                width:"95px",
            },
            "span":{  }
        },
        "&.n5":{
            "& div.text":{
                left:"57px",
                top:"94px",
                height:"71px",
                width:"95px",
            },
            "span":{  }
        },
        "&.n6":{
            "& div.text":{
                left:"235px",
                top:"70px",
                height:"75px",
                width:"101px",
            },
            "span":{  }
        },
        "&.n7":{
            "& div.text":{
                left:"233px",
                top:"77px",
                height:"75px",
                width:"101px",
            },
            "span":{  }
        },
        "&.n8":{
            "& div.text":{
                left:"271px",
                top:"74px",
                height:"75px",
                width:"105px",
            },
            "span":{  }
        }
    }
}));
export const Scientists=({number,text,color}:Props)=>{
    const classes=useStyles({color});
    return (
        <div className={classes.scientists+" n"+(number)}>
            <div className={'text'}><span>{text}</span></div>
            <ReactSVG
                wrapper="div"
                className={"svg c"+color.replace("#",'').toLowerCase()}
                src={process.env.PUBLIC_URL+"/assets/scientists/"+(number)+'.svg'} />
        </div>
    );


}