import * as React from "react";


import {Circle, Text, Image, Layer, Path, Line} from "react-konva";


const radius=30;
const fontSize=25;

type PointProps={
    x:number,
    y:number,
    x2:number,
    y2:number ,
    color:string
}

export const DrawLine=(props:PointProps)=>{
    return (
        <Line
            // x={this.state.from.x}
            // y={this.state.from.y}
            // points={[0, 0, this.state.to.x-this.state.from.x, this.state.to.y-this.state.from.y]}
            points={[props.x,props.y,props.x2,props.y2]}
            tension={1}
            // stroke={this.props.color?this.props.color:"#000"}
            stroke={"#000"}
        />
    );
}
