import * as React from 'react';
import {useEffect} from "react";


const styles:React.CSSProperties={
    fontSize: 12,
    width:"100%",
    height:"100%",
    background:"#fff",
    position:"absolute",
    left:0,
    top:0,
    zIndex:500
};
const stylesText:React.CSSProperties={
    fontSize: 12,
    width:"100%",
    background:"#fff",
    position:"absolute",
    left:0,
    top:0,
    zIndex:505
};
type Props={
    close:()=>void

}

export const OnGameAd=(props:Props)=>{

    useEffect(()=>{

       let timeout=setTimeout(()=>{
           if(props.close)props.close();
       },5000);
        return ()=>{
            clearTimeout(timeout);
        }
    });

    useEffect(() => {
        const pushAd = () => {
            try {
                //@ts-ignore
                const adsbygoogle = window.adsbygoogle
                console.log({ adsbygoogle })
                adsbygoogle.push({})
            } catch (e) {
                console.error(e)
            }
        }

        let interval = setInterval(() => {
            // Check if Adsense script is loaded every 300ms
            //@ts-ignore
            if (window.adsbygoogle) {
                pushAd()
                // clear the interval once the ad is pushed so that function isn't called indefinitely
                clearInterval(interval)
            }
        }, 300);

        return () => {
            clearInterval(interval)
        }
    }, [])
    return (
        <div style={styles} onClick={()=>{
            if(props.close)props.close();
        }}>
            <div style={stylesText}>Вы вернетесь в игру через 5 секунд</div>
            {/*<script async*/}
            {/*        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4281947975473771"*/}
            {/*        crossOrigin="anonymous"></script>*/}
            <ins className="adsbygoogle"
                 style={{display:"block",height:'100%',width:"100%"}}
                 data-ad-client="ca-pub-4281947975473771"
                 data-crossorigin="anonymous"
                 // data-ad-slot="12121212"
                 // data-ad-format="auto"
            />
        </div>
    );

}