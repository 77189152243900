import * as React from 'react';
import {useParams} from "react-router-dom";
import {LevelSuccess} from "./LevelSuccess";


export const  LevelSuccessPage:React.FC<{}>=( )=>{
    const params=useParams<{level:string}>();

    if(!params.level){
        throw new Error("Нужно указать уровень");
        return null;
    }
    return (<LevelSuccess level={parseInt(params.level)} />);
}