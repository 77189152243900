import * as React from 'react';

import IconLevels from './../images/levels.svg' ;
import {createUseStyles} from "react-jss";

const useStyles=createUseStyles<string,any>({
    Button:{
        backgroundImage: `url(${IconLevels})`,
        position:"absolute",
        paddingLeft:"46px",
        top:"44px",
        right:"30px",
        whiteSpace:"nowrap",
        zIndex:"10",
          display:"block",
        backgroundColor:"transparent",
        backgroundPosition:"left center",
        border:"0px",
        backgroundRepeat:"no-repeat",
        outline:"0px none",
        cursor:"pointer",
        height:26,
        textTransform:"capitalize",
        "@media  only screen and (min-width: 997px)":{
                fontSize:20,
                fontWeight:700,
        },
        "@media  only screen and (min-width:319px) and (max-width: 715px)":{
            position:"absolute",
            paddingLeft:"26px",
            top:"24px",
            right:"15px",
            whiteSpace:"nowrap",
            zIndex:"10",
            display:"block",
            width:"0",
            overflow:"hidden",
            backgroundColor:"transparent",
            backgroundPosition:"0",
            border:"0",
            backgroundRepeat:"no-repeat",
            outline:"0 none",
            cursor:"pointer",
            color:"transparent",
        },
    }
});
type Props=
    Partial<React.ButtonHTMLAttributes<HTMLButtonElement>>

export const SelectLevelButton:React.FC< Props>=({className,...props})=>{

    const classes=useStyles();
    return (
        <button
            {...props}
            className={className?className+" "+classes.Button:classes.Button}
        />
    );
}