import i18n from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import backend from "i18next-xhr-backend";

// import i18n from "i18next";
import LocalStorageBackend from "i18next-localstorage-backend"; // primary use cache
import XHR from "i18next-xhr-backend";
import {initReactI18next} from "react-i18next"; // fallback xhr load


// i18n
//     //@ts-ignore
//     .use(initReactI18next)
//     .use(LanguageDetector)
//     // .use(backend)
//     .init({
//     // lng: 'en',
//         fallbackLng: 'en',
//         debug: true,
//         react: {
//             useSuspense: false //   <---- this will do the magic
//         },
//     interpolation: {
//         escapeValue: false, // not needed for react as it escapes by default
//     },
//     resources,
// });

const fallbackLng = ["en"];
const availableLanguages = ["en", "ru",'de'];

i18n
    .use(backend)
    // .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step
    .use(LanguageDetector) // detect user language

    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
        debug: false,
        // withList: availableLanguages,
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        },
        backend:{

            loadPath: process.env.PUBLIC_URL+'/locales/{{lng}}.json',
        }

    });

export default i18n;