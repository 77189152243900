import * as React from 'react';

import Icon from './RestartButton/zanovo.svg' ;
import {createUseStyles} from "react-jss";
type Props={
    styles?:React.HTMLProps<HTMLButtonElement>['style']
}

const useStyles=createUseStyles<string,any>({
    Button:{
        backgroundImage: `url(${Icon})`,
        paddingLeft:"35px",
        position:"absolute",
        background:"transparent no-repeat 10px 2px",
        border:0,
        outline:0,
        cursor:"pointer",
        height:40,
        width:40,
        fontFamily:" 'Montserrat' , sans-serif",
        lineHeight:"15px",
        backgroundPosition:"left center",
        overflow:"hidden",
        left:"15px",
        bottom:"20px",
        fontStyle:"normal",
        fontWeight:"bold",
        fontSize:"12px",
        textTransform:"capitalize",
        "@media  only screen and (min-width: 997px)":{
            paddingLeft: 52,
            lineHeight: "40px",
            left: 30,
            bottom:42,
            fontStyle:"normal",
            fontWeight:"700",
            fontSize:"20px",
            width:"auto",
            "&.levelSuccess":{
                marginLeft:" -308px",
                bottom: "auto",
                top: "50%",
                marginTop: "189px",
                left: "50%",
            }
            ,
            "&.badResult":{
                left:"50%",
                marginLeft:-63,
                color:"#000",
                width:"auto",
                fontSize:"20px",
                paddingLeft: 52,
            }
        },
        "@media  only screen and (min-width:319px) and (max-width: 997px)":{
            bottom:20,
            top:"auto",
            margin:"0",
            left:"35px",
            color:"transparent",
            "&.levelSuccess":{
                bottom: 48,
            },
            "&.badResult":{
                left:"50%",
                marginLeft:-73,
                color:"#000",
                width:"auto",
                fontSize:"20px",
                paddingLeft: 52,
            }
        }
    }
});

export const RestartButton:React.FC< Partial<React.ButtonHTMLAttributes<HTMLButtonElement>> >=({className,...props})=>{

    const classes=useStyles();
    const innerStyle={backgroundImage: `url(${Icon})`};
    return (
        <button
             {...props}
             className={className?className+" "+classes.Button:classes.Button}
        />
    );
}