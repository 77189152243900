import * as React from 'react';
import {useParams} from "react-router-dom";
import {LevelPlay} from "./LevelPlay";
import {Logo} from "./Logo";

export const LevelPlayPage:React.FC<{}>=( )=>{
    const params=useParams<{level:string}>();

    if(!params.level){
        throw new Error("Нужно указать уровень");
        return null;
    }
    return (<React.Fragment>
        <Logo />
        <LevelPlay key={params.level} level={parseInt(params.level)} />

    </React.Fragment>);
}