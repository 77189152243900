import * as React from 'react';
import {LangSteps, starsCount} from "../lib/parseLevelData";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {GameContext} from "../lib/GameContext";
import {LevelResultType, LevelType} from "../lib/Types";
import {useNavigate} from "react-router-dom";
import {createUseStyles} from "react-jss";
import {Stars} from "./Stars";
import {DesktopFooter} from "./DesktopFooter";
import {useWindowSize} from "../hooks/useWindowSize";


const useStyles = createUseStyles((props:any)=>({

    levelSelectList: {
        padding: "0px",
        paddingTop: "95px",
        position:"relative",
        boxSizing: "border-box",
        width: "100%",
        margin:"auto",
        textAlign: "center",
        "@media  only screen and (min-width:319px) and (max-width: 715px)":{
            paddingTop: 73,

        },
        "& > div":{
            width:1044,
            "@media  only screen and (min-width:319px) and (max-width: 715px)":{
                width:"auto",
            },
            "@media  only screen and (min-width:715px) and (max-width: 1044px)":{
                width:710,
            },
        },

        "& .item": {
            overflow: "hidden",
            cursor: "pointer",
            position: "relative",
            boxShadow: "0px 1px 3px #bababa ",
            marginLeft: "6px",
            marginRight: "6px",
            padding: "0px",
            width: "336px",
            height: "120px",
            marginBottom: "30px",
            display: "block",
            float: "left",
            background: "#fff",
            borderRadius: "5px",
            textAlign: "left",
            "@media  only screen and (max-width: 715px)":{

            },
            "@media  only screen and (min-width:319px) and (max-width: 715px)":{
                    width:305,
                clear:"left",
                marginBottom:16,
            },
            "&:hover": {
                boxShadow: "0px 1px 4px #808080",
            },
            "&.disabled": {
                color: "#C2C2C2",

                "& .stars*": {
                    display: "none",
                }

            },

            "& .name": {
                textTransform:"capitalize",
                position: "absolute",
                left: "16px",
                top: "16px",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "32px",
                lineHeight: "24px",
                "@media  only screen and (min-width:319px) and (max-width: 715px)":{
                    fontSize: 30,
                },
            },

            "&  div[class^='stars']": {
                left: "15px",
                top: "50px",
                position: "absolute",
                "& div": {
                    marginRight: "5px",
                    display: "inline-block",
                }
            },

            "& .text": {
                position: "absolute",
                top: "99px",
                left: "16px",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontSize: "12px",
                lineHeight: "10px",
                display: "inline-block",
            },
            "& .text1,.text2": {
                display: "inline-block",
            },
            "& .text1": {
                fontWeight: "bold",

                marginRight: "15px",
            },
            "& .text2": {

                textTransform:"capitalize",
            },

            "& .icon": {
                width: "110px",
                height: "98%",
                backgroundPosition: "center center !important",
                backgroundRepeat: "no-repeat !important",
                position: "absolute",
                right: "0px",
                top: "1%",
                backgroundSize: "contain !important",
            },

            "& .textPromo": {
                position: "absolute",
                left: "16px",
                top: "48px",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "130%",
                "& a": {
                    textDecoration: "none",
                    color: "#0396FD",
                    borderBottom: "0.5px solid #0396FD",
                    "&:hover": {
                        borderColor: "#fff",
                    }
                }
            }


        },
        "& .footer": {
            textAlign: "left",
        }
    }

}));

export const LevelsList=()=>{
   const gameContext=useContext(GameContext);
    // const result=opened?gameContext!.results.find(t=>t.level===level):null;4
    const classes=useStyles();

    // const ws=useWindowSize();
    const {t}=useTranslation();
    return (
        <div className={classes.levelSelectList}>
            <div style={{display:"inline-block",margin:"auto"}}>
            {gameContext!.levelsData.map((level,index)=>{
                   let opened=gameContext!.openedLevel>index?true:false;
                    let result=opened?gameContext!.results.find(t=>t.level===index+1)!:null;
                   return <LevelOnLevelSelect key={index}  levelInfo={level} level={index+1} opened={opened} result={result}  />

            })}

            <div
                className={'item '}
            >
                <span className="name promo">{t('Свой уровень')}</span>

                <div className="textPromo">
                    Придумайте свой уровень и пришлите <br/> на <a href="mailto:Info@dontbenegative.app">Info@dontbenegative.app</a>. Мы добавим интересные уровни, а их авторам подарим платную версию игры.
                </div>


            </div>
            </div>



        </div>
    );
}


type PropsLevelInfo={
    level:number,
    levelInfo:LevelType,
    result:LevelResultType | null | undefined,
    opened:boolean
}

const  LevelOnLevelSelect:React.FC<PropsLevelInfo>=({level,levelInfo,result,opened}:PropsLevelInfo)=>{

    const {t}=useTranslation();
    const stars=(result?starsCount(result):0);
    const navigation=useNavigate();
    const classes=useStyles();
    return (
        <div
            className={'item '+(opened?"":'disabled')}
            onClick={()=>{
                if(opened){
                    navigation("/level"+level) ;
                }

            }}
        >
            <span className="name">{t("уровень")} {level}</span>

            {opened && <Stars>
                <div className={stars>=1?"active":''}></div>
                <div className={stars>=2?"active":''}></div>
                <div className={stars===3?"active":''}></div>
            </Stars>}

            <div className="text">
                {result?(<div className="text1">{result.moves} {t(LangSteps(result.moves))}</div>):null}
                <div className="text2">{t("цель")}: {levelInfo.best} {t(LangSteps(levelInfo.best))}</div>
            </div>

            <div  className={"icon"} style={{backgroundImage:"url('"+process.env.PUBLIC_URL+"/assets/icons/"+level+(opened?"":'_grey')+".svg')"}}></div>

        </div>
    );
}