import * as React from 'react';

import {createUseStyles} from "react-jss";
import LogoIcon from "./../images/logo.svg"
import {useNavigate} from "react-router-dom";
type Props={
    styles?:React.HTMLProps<HTMLButtonElement>['style']
}

const useStyles=createUseStyles<string,any>({
    Logo:{
        position:'absolute',
        top:36,
        left:30,
        cursor:"pointer",
        zIndex:15,
        "@media  only screen and (min-width:319px) and (max-width: 715px)":{
            top:20,
            left:15,
        },
    }
});

export const Logo:React.FC< Partial<React.HTMLAttributes<HTMLDivElement>> >=({className,...props})=>{
    const navigation=useNavigate();
    const classes=useStyles();
    return (
        <div className={classes.Logo}
             onClick={()=>{navigation("/")}}
        ><img src={LogoIcon} alt=""/></div>
    );
}