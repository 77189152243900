import {LevelResultType, LevelType} from "./Types";


type ValidColorString = string & { __validColorString: true };

  function isValidColorString(x: string): x is ValidColorString {
    const re = /#[0-9a-fA-F]{3}([0-9a-fA-F]{3})?/g; // you want hex, right?
    return re.test(x);
}

type sourcePoint = [number,number];



type resultPoint={
    x:number,
    y:number,
    value:number,
    index:number
}
/**
 *
 * @param levelInfo
 * @param width Ширина текущего окна
 * @param height Высота текущего окна
 */
export const parseLevelData=(levelInfo:LevelType,width:number,height:number)
:LevelType    =>{
    // console.log("levelInfo",levelInfo);
    let swipe=false;
    // width=width*0.9;
    // height=height*0.9;
    width=width;
    height=height;
    if(width>height){
        swipe=true;
    }else{
        swipe=false;
    }
    swipe=false;
    let maxX=0;
    let minX=99999;
    let maxY=0;
    let minY=99999;

    let offsetX=0;
    let offsetY=0;
    levelInfo.points.map((point,index)=>{
        if(!swipe){
            if(point[0]<minX)minX=point[0];
            if(point[0]>maxX)maxX=point[0];
            if(point[1]<minY)minY=point[1];
            if(point[1]>maxY)maxY=point[1];

        }else{

            if(point[1]<minX)minX=point[1];
            if(point[1]>maxX)maxX=point[1];
            if(point[0]<minY)minY=point[0];
            if(point[0]>maxY)maxY=point[0];
        }

    });


    let workHeight=height*0.5;

    if(levelInfo.text2 || levelInfo.text1){
        // workHeight=height*0.4;
        workHeight=workHeight-50;
    }


    let workWidth=width*0.76;

    let potencialWidth=((maxX-minX)/(maxY-minY))*workHeight;
    let potencialHeight=((maxY-minY)/(maxX-minX))*workWidth;

    //
    // console.log("width",width);
    // console.log("height",height);
    // console.log("workWidth",workWidth);
    // console.log("workHeight",workHeight);
    // console.log("maxY-minY",maxY-minY);
    // console.log("maxX-minX",maxX-minX);
    // console.log("potencialWidth",potencialWidth);
    // console.log("potencialHeight",potencialHeight);
    if(potencialWidth<=workWidth){
        // console.log("подгоняем по ширине");
        offsetX=((width-potencialWidth)/2);
        // let sizeOfY=(maxY-minY===0?0:((maxY-minY)-minY)*width/(maxY-minY));
        offsetY=((height-workHeight)/2);
        // offsetY+=((workHeight-sizeOfY)/2);
        width=potencialWidth;
        height=workHeight;


    }else if(potencialHeight<=workHeight){
        // console.log("подгоняем по высоте");
        offsetY=((height-potencialHeight)/2);
        offsetX=((width-workWidth)/2);
        width=workWidth;
        height=potencialHeight;

    }else{
        throw new Error("очень странный размер окна");
    }

    //
    // console.log("offsetX",offsetX);
    // console.log("offsetY",offsetY);
    // console.log("width",width);
    // console.log("height",height);
    if(levelInfo.text2 || levelInfo.text1){
        // workHeight=height*0.4;
        offsetY=offsetY+50;
    }
    let points:resultPoint[]=[];
    let tx=0;
    let ty=0;
    levelInfo.points.map((point,index)=>{
        // tx=((point[0]-minX)*width/(maxX-minX))+offsetX;
        // ty=(maxY-minY===0?0:(point[1]-minY)*height/(maxY-minY))+offsetY;
        if(swipe)point[0]=(point[0]-maxY-minY)*-1;
        points.push({
            x:((point[!swipe?0:1]-minX)*width/(maxX-minX))+offsetX,
            y:(maxY-minY===0?0:(point[!swipe?1:0]-minY)*height/(maxY-minY))+offsetY,
            value:levelInfo.values[index],
            index:index
        });

    });


    return {
        ...levelInfo,
        realPoints:points,
        links:levelInfo.links,
        color:levelInfo.color,
        best:levelInfo.best,
    }


};

export function LangSteps(num: number): string {

    if (num === 1) {
        return "ход";
    }
    if ((num > 1 && num < 5)) {
        return "хода";
    }
    if ((num > 4 && num < 20)) {
        return "ходов";
    }
    let ost = num % 10;
    if (ost === 1) {
        return "ход";
    }
    if (ost > 1 && ost < 5) {
        return "хода";
    }
    if ((ost > 4 && ost < 10) || ost === 0) {
        return "ходов";
    }
    return '';

}

export function starsCount({cancels, best, moves}: LevelResultType): (1 | 2 | 3 | 0) {
    let stars: (1 | 2 | 3 | 0) = 0;
    if (cancels === 0 && moves <= best) {
        stars = 3;
    } else if (cancels === 1 && moves <= best) {
        stars = 2;
    } else if (cancels === 0 && moves <= best + Math.floor(best * 0.3)) {
        stars = 2;
    } else if (cancels === 2 && moves <= best) {
        stars = 1;
    } else if (cancels === 0 && moves <= best + Math.floor(best * 0.6)) {
        stars = 1;
    }
    return stars;
}