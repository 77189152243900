import * as React from 'react';
import {createUseStyles} from "react-jss";
import {ReactHTMLElement} from "react";

const useStyles=createUseStyles((props?:any)=>({
    stars:{
        "& div":{
            marginRight:5,
            display:"inline-block",
            background: "no-repeat center center",
            backgroundImage:"url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAcCAYAAAB2+A+pAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOASURBVHgBrVZbTttQEJ2517Z4VXVXQJAAqVI/wgqarKDpH49IhBVQVkCyAmAFpFKJkPpBdoC7gtCvlgJNuoK6alAhvvZ0xibl5QScMj/Xvq9z53VmAEYQc7xUCk7LP83pyi6MKApGEa22gACIoBKcLBZgBMkMHJyUCwyYC4FqgODzKzZhBMkMjEirSNAZm9vbjkLc4akCtSouZJRMwH++LObEvHzKk3/HsbZl7E303kFGyQSstSrIGARhTUacqbOpwVMK1yGjZAJmAPGnN/5yv3M9i/IIN2uQPRrYnJZLElRE9P7mvD33wRslyHDYIrVK7oU75to9zMcpFAFY83szd/ddHperStFmGEYbShnPPg86uND0h91t9b4t51mTEpvxOSK4ohWKZgpdQ+Rahh8gdiH0CaJi2iUSZEEQ5LQ8DhwwUw6n3QpI9BNCJ9aQxzCkHzI68406Mvu0BYyXfCTyZaMsRhH9ihA7iueUUr7+3fUe0kKU4L05fnAOI3K1xunkboBYGUy+2V1rlphPfEQUFe35xhH8hzjJ+dQ7xG3h5OQWg1fkXwVhWOTX+IjYuvi6lDkfHyMCaqYmDxNQqsWmjhfaFdeY4JA/8/xXtecaNXgiEdKxbX1IhC5htOHMNuoyfyuqueJsswPWnwo88TkexKDsSueGK++lExeBKgNzTlLT6jpruFD3YQSR0kla73KQ+eLO26QzII+vweHI6trFrOAxqFIHct6Y8O1dUJFU5mI2qkrI82f+cvyyAlmF81ly2OqeF9NABwKLSOTJyNGeueTF9ZqpdVjeDwSWwEiAE+bJIqKt1jA9bM+wIhED93pBZlIJKfrEkVyCUYBRwRt5+dSrj5mBObDkjEvflwdqbQ1aQMA8++nzvUtjFppYR8KK8LqUyX48/NtjwiZYeiswIEWlnnZ/qsZXLU6O/du8Od87K6+bZ1NtflZV2h+M9+CutLlypr8vjmTmf6XgNQyQVOB+i9P3r3QXXOZaTAbCbJKbM9bs3prUZu5yN7jQFCxLt4OT5c3rxo88fmBhEHAqgYgGUqMZYMGyLe6hqZTUVlyLO44UC2mtq2yh1auSWuOS6mqALSagF2kElM5cZyst1iInuSSNO8uO1e1uP1SP44KgtVQhcVMnqcVh0Z7b9+7uTTU1RSB9lZjMM8YsCJM9BCoivo3NL6wXJflsTDoP/AUFBMz1RS5XgAAAAABJRU5ErkJggg==')",
            width:30,
            height:28,
            "&.active":{
                backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAcCAYAAAB2+A+pAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIxSURBVHgBxVZdbtNAEJ5ZJxEgJHyDJlISibfkBDgnwI+lRMI9AeQE0BPQG5BIdcVjb0Bu0L4haETDCchLRBWvPcy4RUpcr9u10/aTrF3v2vvtzs83C1AC+scbP5oN/+jZ2y9QEgrKwFGfgcglgiA63/WgBKyJo/Ohx4TNtV18hBKwJkakd5khj04DFyxhRfz3+25TzJsdXz1bfQBLWBE7jvJyF1H4Hu6TmAlM/nRtg+zOxHo29DeDKgu7IMOiSTr13Uv3iVtfYU9SqJgYII6TkVJ6Wl9Gc+yfLIq+xdXPvR4v6LMZXyCC5GYT+SGFruQqVACvMyeEedrnNo7pt7SN7vEYWX0ubjvJtkFE+woSeBSoKI4HYhJ4MNBBauq0exG4WkffuNuDewMuCJNRo308Tt/Wp7jiHLIDrMXgTqSUDPikZ/9HNvK43j5i6cMD2CLEjVrr/jrpDeKUvHP0aYvkZxJDT19+nWcnjALC+R0gYulCLyd1lsu+SUiMkimRBxUQE02K1MtILIoGFeA4sFM0X1QkKhEToQ9liFHBa6gGl37t7dgTA1YWk0jDAGyIr684TSgAV5nxbVKrFLwyzuUNmq4415hqHbdq7XC/1g1bBDAybwA9sCHm2nxjp1eL46DeCTcEodEJD0Uk2EKT7D9iNdMNNJeYi/eaf3EhSiZiwKo2zfteNtLohoFYInt6/fwyN1ZqucQJTFDSCfFER3qUJ3mmDXDTEtVTcHUxjOJ8N/wD+gzj/hKFF/UAAAAASUVORK5CYII=' )",
            }
        }
    }

}));
export const Stars:React.FC<Partial<ReactHTMLElement<HTMLDivElement>> >=(props)=>{
        const classes=useStyles();
    return (<div {...props} className={classes.stars}/>)
}